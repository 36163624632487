import React, { useState, useEffect, useRef,createElement } from "react";
import { ReactPhotoSphereViewer} from 'react-photo-sphere-viewer';
// import { CompassPlugin } from '@photo-sphere-viewer/core';
import { MarkersPlugin } from "@photo-sphere-viewer/markers-plugin";
import "@photo-sphere-viewer/markers-plugin/index.css"
import queryString from 'query-string';
import { ReactImageTurntable  } from "react-image-turntable";
import {useNavigate, useSearchParams,useLocation } from "react-router-dom";

import {db} from './firebase.js'
import {doc, getDoc, addDoc,getDocs, collection, serverTimestamp,where,query,onSnapshot } from 'firebase/firestore';

//MATERIAL UI
import {
  Button, ClickAwayListener,
  Dialog, DialogActions, DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid, Grow, IconButton,
  InputBase, Menu,
  MenuItem, MenuList,
  Paper, Popper,
  Select, Stack,
  Typography,Box, Fab, 
  Tooltip,Fade,Card,CardMedia,CardContent,
  CardActions,
  Divider
} from '@mui/material';
import DialogSelector from './DialogSelector';
import Slider, {SliderThumb} from '@mui/material/Slider';
import WbSunny from '@mui/icons-material/WbSunny';
import {createRoot} from "react-dom/client";
import PanoMarker from "./HousingSelector/components/user/PanoMarker";
import {UnitCard} from "./HousingSelector/components/user/UnitCard";
import UnitList from "./HousingSelector/components/user/UnitList";
import {units} from "./HousingSelector/data/testUnits";

import HouseNavigationCards from './HouseNavigationCards';
import ImageCards from './ImageCards';
import { isVisible } from "@testing-library/user-event/dist/utils/index.js";
import { Visibility } from "@mui/icons-material";

function getLocationParams() {
  let url = window.location.search;
  return queryString.parse(url);
}

var markersPlugs;
var selected2;
var startimage;
var startyaw= 0;
var startpitch =0;
var imagesPano = [];
var debug = false

var number = 0;
var handleNavigationChange = function(){};
//window.navigation.addEventListener('navigate', handleNav())

//window.navigation.addEventListener('pushState', () => console.log('pushState event ', window.location.search));
window.addEventListener('popstate', () => handleNavigationChange()); //Listener to handle if user is clicking back button. Go to the recently pushed thing
/*
window.navigation.addEventListener('navigate', () => {
  console.log('page changed');
  //handleNav(getLocationParams.id);
  console.log(window.location.search)
});
*/

var lastTimeResized = new Date().getTime();
var resizeTimeout;


var handleMouseMove = function(){};
var handleTouchStart = function(){};

// window.addEventListener("mousemove", updatePosition, false);
window.addEventListener("mousemove", function(e){handleMouseMove(e)}, false);
window.addEventListener("touchstart", function(e){handleTouchStart(e)}, false);
// window.addEventListener("touchend", function(e){End(e)},  false);
// window.addEventListener("touchmove", function(e){Moving(e)},  { passive: false });

handleMouseMove = function HandleNav(pos)
{
  //if (hoverPlateUnit)
  {
    //let posFix = {x: pos.x, y:pos.y}
    //setPlatePos(posFix)
if (document.getElementById('hoverPlate'))
{ 
  document.getElementById('hoverPlate').style.top = (pos.y)+'px'
    document.getElementById('hoverPlate').style.left = (pos.x+40)+'px'
}
    //console.log(document.getElementById('hoverPlate').style)
  }
  //console.log(pos)
}


const unitPreviewCardsStyle = {
  position: 'absolute',
  right: '400px',
  top: '0px',
  width: '350px',
  zIndex: 100,
}


function Pano() {
  const id= getLocationParams().id
  const [target,setTarget] =useState(getLocationParams().target)
  
  const [data, setData] = useState(null)
  //const [image, setImage] = useState("")  //Not actually used
  const [selected, setSelected] = useState(null)
  const [previous, setPrevious] = useState(null)
  //const [viewstyle, setViewstyle] = useState ("panorama") //Select between "turntable" or "panorama"
  const [viewstyle, setViewstyle] = useState ("panorama") //Select between "turntable" or "panorama"

  const [smallscreen, setSmallscreen] = useState(false);
  const [screensize, setScreensize] = useState([0,0]);
  const [mainTarget, setMainTarget] = useState("")


  //var myRef
  //const myRef = useRef(null);
  const myRef = useRef<Window | null>(null)
  const [sunslider, setSunslider] = useState(false);
  const [sliderValue, setSliderValue] = React.useState(0);
  const [showSlider, setShowSlider] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedUnit, setSelectedUnit] = useState(null);
  const [hoverUnit, setHoverUnit] = useState(null);
  const showHover = (!selectedUnit && hoverUnit) || (selectedUnit && hoverUnit && hoverUnit.id != selectedUnit.id) ? true : false;
  const showSelected = selectedUnit ? true : false;

  const [panoTags, setPanoTags] = React.useState({});
  const [mainhouseButtons, setMainhouseButtons] = React.useState([]);
  const [mainBuildingUI, setMainBuildingUI] = useState(false)
  const [buttonHouseNavigation, setButtonHouseNavigation] = useState(false)

  const [currentHouseLink, setCurrentHouseLink] = React.useState(null);

  const [buttonHouse, setButtonHouse] = React.useState([]);
  

  const [colorTheme, setColorTheme] = React.useState({background:"#1349a9",icon:"#FFFFFF"});

  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [boligvelger, setBoligvelger] = React.useState(false);

  const [hoverPlateUnit, setHoverPlateUnit] = useState(null);
  
  const [testlol, setTestLol] = useState(null);

  const [platePos, setPlatePos] = useState({x:0,y:0});
  const [topbar, setTopbar] = useState()

  

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  //temp until we make a better way to setup and pass the target


  //const location = useLocation();
  //const [markers, setMarkers] = useState([]);
  //const [imagesPano, setImagesPano] = useState([]); //used for PanoramaSun

  //TurnTABLE
  const [images, setImages] = useState([]); //used for Turntable
  
  const [rotationDisabled, setRotationDisabled] = useState(true);
  const [buttons, setButtons] = useState([]); //buttons for Turntable


  function handleOnSelectedUnit (unit) {
    if (selectedUnit && unit.id == selectedUnit.id)
        unit = null;

    setSelectedUnit(unit);
}

  useEffect(() => {
/*
    fetch("https://fortrinn.s3.eu-north-1.amazonaws.com/projects/UmMQdmmgjSLCfR1J3awx/pano/82180.jpg", {
    }).then((response) => {
      console.log('image fetched')
      //console.log('sunslider loaded complete', element)
    })
*/
    Start();
    
    
    //TODO: Legge inn slik at backbutton responderer på href byttingen
    
    //window.addEventListener('hashchange', handleHashChange);

    const updateDimensions = () => {

    let now = new Date().getTime();
    if (now - lastTimeResized > 1000) 
    {
        //console.log('updating dimensions')
        const width = window.innerWidth
        const height = window.innerHeight
        setScreensize([width,height])
        if (width>1000)
        {
          setSmallscreen(false);
        }
        else
        {
          //console.log('smallscreen')
          setSmallscreen(true)
        }
        lastTimeResized = new Date().getTime();
        //setWindowWidth(width)
      }
      else
      {
        //console.log('updating resizing too fast')
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(updateDimensions, 1000);
      }
    }
    window.addEventListener('resize', updateDimensions)
    updateDimensions();




      }, []);


      

      handleNavigationChange = function HandleNav()
      {
        //console.log('set targettttttttt')
        let newTarget= getLocationParams().target
        if (newTarget == null || newTarget =="" || newTarget =="undefined")
        {
          //console.log('target is null')
          newTarget = mainTarget
        }
        setSelected(data[newTarget])
        //console.log('target ', getLocationParams().target)
      }
async function Start()
{
  //if (viewstyle =="panorama")
  
 

  {
    
    await GetAllPanoTags();
  await GetProjectInfoCollection();
  await SetWebData();
  await SetButtonsData();
  
  setBoligvelger(false)
  }
  if (viewstyle =="turntable")
  {
    //Get Lightstream/Turntable data from database. This data will be put in pano database later
    var lightstreamdata = await GetProjectInfoLightstream()

    let tempbuttons =[];
if (lightstreamdata)
  for (const [key, value] of Object.entries(lightstreamdata.points)) {
    //console.log(`${key}: ${value}`);
    //console.log(value.name)
    
    let tempbut={
      name:value.name,
      points: JSON.parse(value.points).reverse()
    }
    tempbuttons.push(tempbut)
  }
  setButtons(tempbuttons)
  let links = setNewImageArrayHorizontal()
    setImages(links.reverse())
  }
}

useEffect(() => {
  //TODO: Legge inn slik at backbutton responderer på href byttingen
  
  //window.addEventListener('hashchange', handleHashChange);

    //console.log('house link changed')
    if (currentHouseLink)
    {
    //console.log(currentHouseLink.buttons)
    GetConnectedButtons()
    }
    else
    {
    //console.log('house link empty')
    setButtonHouse([])
  }

    }, [currentHouseLink]);

async function GetProjectInfoCollection()
{
  if (debug)
  console.log('getting info from database!')

  let projectidarg = id
  const q = query(collection(db, "stream",projectidarg, 'pano'))
  const querySnapshot = await getDocs(q);
  var tempData = {};
  querySnapshot.forEach((doc) => {
     //console.log(doc.data())
     let lol = doc.data()
     //let str = lol.id
     lol.image = "https://fortrinn.s3.eu-north-1.amazonaws.com/projects/"+projectidarg+"/pano/" + lol.id +".jpg"
     lol.thumb = "https://fortrinn.s3.eu-north-1.amazonaws.com/projects/"+projectidarg+"/pano/" + lol.id +"_thumbnail.jpg"
     let lal = {[lol.id] : lol}
     //tempData.append("test",lol)
     tempData = {...tempData, ...lal}

     if (lol.main)
     {
      setMainTarget(lol.id)
      if (target == null || target =="" || target=="undefined")
      {
        //console.log('target is empty')
        setTarget(lol.id)
      }
      //console.log('main is ', lol)
     }
  });
  
        //temp until we make a better way to setup and pass the target
        

  let item
  if( tempData.hasOwnProperty(target) ) {
    item = tempData[target];
    //console.log( item );
    tempData.selected = item
    tempData.selected.image = "https://fortrinn.s3.eu-north-1.amazonaws.com/projects/"+projectidarg+"/pano/" + target +".jpg"
  }
  setData(tempData)
  //console.log(tempData)
}



//Get info on specific ID and target in panodatabase
async function GetProjectInfo()
{
  let projectidarg = id
        if (projectidarg)
        {
        if (target!=undefined)
        {
          const q = query(collection(db, "stream",projectidarg, 'pano'), where("id", "==", target));
          const querySnapshot = await getDocs(q);
          let docu;
          querySnapshot.forEach((doc) => {
            //console.log(doc.data())
            let tempdoc = doc.data()
            tempdoc.image = "https://fortrinn.s3.eu-north-1.amazonaws.com/projects/3iUfCJSzDaWjUS5XFBhY/pano/aurora.jpg"
            setData(tempdoc)
          });
         
        }
        }
}


//Get info on specific ID and target in panodatabase
async function GetConnectedButtons()
{

  let projectidarg = id
        if (projectidarg)
        {

/*
          const q = query(collection(db, "stream",projectidarg, 'pano'), where("id", "in", currentHouseLink.buttons));
          const querySnapshot = await getDocs(q);
          let docu;
          let buttons = []
          querySnapshot.forEach((doc) => {
            console.log(doc.data())
            buttons.push(doc.data())
            //let tempdoc = doc.data()
            //tempdoc.image = "https://fortrinn.s3.eu-north-1.amazonaws.com/projects/3iUfCJSzDaWjUS5XFBhY/pano/aurora.jpg"
            //setData(tempdoc)
          });
          setButtonHouseNavigation(buttons)
          //console.log('trying to find buttons from ', currentHouseLink.buttons)
         console.log(buttons)
*/

        if (selected.poi)
        {
          let buttons = []
          selected.poi.forEach(element => {
            buttons.push(data[element])
          });
/*
          currentHouseLink.buttons.forEach(element => {
            buttons.push(data[element])
          });
*/

          setButtonHouse(buttons)
          //console.log(buttons)
        }

        }


}


async function GetCollection(nr1="",nr2="",nr3="",nr4="",nr5=""){
  //const projectref = props.db.collection('stream').doc(props.projectid);
  //const doc = await projectref.get();
  //const q = query(collection(db, "cities"), where("capital", "==", true));
  //const q = query(collection(db, "cities"), where("capital", "==", true));
  //const document = await getDocs(collection(props.db, nr1, nr2, nr3, nr4,nr5), where("mainHouseButton","==", true));
  const document = await getDocs(collection(db, nr1, nr2, nr3, nr4,nr5)); //Get whole collection
  let data={}
  document.forEach((doc) => {
    //console.log(doc.id, " => ", doc.data());
    data[doc.id] = doc.data()
  })
  //console.log(data)
  //console.log(document.data())
  if (data=={}) {
  return {}
  } else {
    //console.log(document.data().units)
    return data
  }
  
}

async function SetButtonsData(){
  let projectid = id

  let lol = await GetCollection('stream',projectid,'buttons')
  let mainhouse = []
  

  Object.keys(lol).map((keyName, i) => {
    {
      mainhouse.push(lol[keyName])
    }
})
setMainhouseButtons(mainhouse)
//console.log(mainhouse)
}

async function SetWebData(){
  
  const document = await getDoc(doc(db, 'stream', id));
  if (!document.exists) {
  return null
  } else {

    try {
      setTopbar(document.data().webbuttons.topbar)
    } catch (error) {
      //console.log('failed topbar')
    }

  //console.log(document.data().webbuttons)
  }
}

async function GetAllPanoTags(){
  const q = query(collection(db, "stream",id,'connectiontags'))
  const querySnapshot = await getDocs(q);
  var tempData = {};
    querySnapshot.forEach((doc) => {
       //console.log(doc.data())
       //tempData=  doc.data()
       let id = doc.data().id
       tempData[id] =   doc.data()
    });
  
  if (tempData)
  {
  setPanoTags(tempData)
  //console.log(tempData)
    //setCurrentHouseLink(tempData)
    //setButtonHouseNavigation(true)
  }
  else
  {
  }
  }

async function RefreshCurrentHouseButtons(){
  const q = query(collection(db, "stream",id,'connectiontags'), where("id", "==", selected.panotags))
  const querySnapshot = await getDocs(q);
  var tempData = null;
    querySnapshot.forEach((doc) => {
       //console.log(doc.data())
       tempData=  doc.data()

    });
  
  if (tempData)
  {
  
    setCurrentHouseLink(tempData)
    setButtonHouseNavigation(true)
  }
  else
  {
    setCurrentHouseLink(null)
    setButtonHouseNavigation(false)
  }
  //setCurrentHouseLink("")
  //console.log('found this ', tempData)
  //let mainhouse = {id:'testid',title:'Aurora', infotext:'Besøk Aurora på nettside', image:"https://firebasestorage.googleapis.com/v0/b/fortrinn-47822.appspot.com/o/stream%2F3iUfCJSzDaWjUS5XFBhY%2F6ZglLUAdR1k6pxviE6CR.jpg?alt=media", link:'https://www.nordbohus.no/hus/aurora'}
  //setCurrentHouseLink(mainhouse)
  }






  useEffect(() => {
    if (data)
     {
      if (data[target].type)
      setViewstyle(data[target].type)
     
        //setViewstyle()

        
      if (data[target].image)
      {
        
        startimage = data[target].image
        try {
          if (data[target].startrotation)
          {
            startpitch=data[target].startrotation.pitch
            startyaw=data[target].startrotation.yaw
            //startpitch=0
            //startyaw=-0.085585
          }
          if (data[target].override.startrotation)
            {
              startpitch=data[target].override.startrotation.pitch
              startyaw=data[target].override.startrotation.yaw
            }
        } catch (error) {
          //console.log('no override startrotation')
        }
       
       // photoSphereRef.current.
        setSelected(data[target])
      }
      else
      {
        //console.log('image not available')
      }
      
     }
   }, [data]);

   useEffect(() => {
    
    if (selected)
     {
      //console.log('new selection')
      //console.log(selected)
        //photoSphereRef.current.setPanorama(selected.image, {speed: '2000rpm',position: { yaw: 0, pitch: 0 },}) //Setting the start position manually
        if (viewstyle =="panorama")
        {
          let forceRot = false;
          let forceYaw = 0;
          let forcePitch = 0;

          if (selected.startrotation)
            {
              forceRot = true
              forcePitch=selected.startrotation.pitch
              forceYaw=selected.startrotation.yaw
            }

          if (selected.override)
            {
              if (selected.override.startrotation)
              {
                forceRot =true
                forcePitch=selected.override.startrotation.pitch
                forceYaw=selected.override.startrotation.yaw
                
              }
            }
            
            if (forceRot)
            {
              photoSphereRef.current.setPanorama(selected.image, {speed: '2000rpm',zoomOut:10,position: { yaw: forceYaw, pitch: forcePitch }})
            }
            else
            {
              photoSphereRef.current.setPanorama(selected.image, {speed: '2000rpm',zoomOut:10})
            }
            //Finn og lag knapper som er bundet med denne selection. Knappene tar en enkelt til og fra steder i en leilighet 
            RefreshCurrentHouseButtons()
            CacheSunImagesPano()
          //MakeMarkers();
        }

        if (selected.id == mainTarget)
        {
          //change_url('nordbohusbyen')
          setMainBuildingUI(true)
        }
        else
        setMainBuildingUI(false)
     }

    
     //return () =>  window.navigation.removeEventListener('navigate', handleNav);
   }, [selected]);


   
   useEffect(() => {
    //console.log('selected updated')

    if(debug)
    console.log('refresh updated')
   }, [mainBuildingUI]);

   useEffect(() => {
    //console.log('selected updated')
    if(debug)
    console.log('platpos updated')
   }, [platePos]);
   


   function ImagecardPr (event) {
    //console.log('imagecardPr')
    //console.log(event)
    //closeUi(false)
    //WebChangeTarget(event)
    //console.log(event)
    
    change_url(event)
  }
   const ChildComponent = () => {
    return (
      <>
      
        <Typography>WABA</Typography>
        <svg viewBox="0 0 100 100" >
       <circle cx="30" cy="30" r="30"  fill='rgba(205, 209, 228, 0.5)' stroke='rgba(144, 198, 149, 0.9)' strokeWidth='4px'/>
       </svg>
      </>
    );
  };

  const MakeMarkers = () => {
    if (markersPlugs)
      {
          markersPlugs.setMarkers()

    for (const [key, value] of Object.entries(selected.markers)) {
      //console.log(`Key: ${key}, Value: ${value}`);
      //console.log(value)

      /*
      let markersDivParent = document.createElement("div");
                const root = createRoot(markersDivParent);
                root.render(PanoMarker(value.id));
*/



      if (value.polygon)
      {

      }
      else
      {
        if (value.clicktype =="point")
        {

          let markersDivParent = document.createElement("div");
                const root = createRoot(markersDivParent);
                //root.render(ChildComponent());
                root.render(PanoMarker(value.id,value.title));


          /*
          const node = document.createElement("li");
          node.style=("")
          const textnode = document.createTextNode("Water");
          //node.appendChild(textnode);
*/
            

//var ele = React.createElement(ChildComponent())

      markersPlugs.addMarker({
        id: value.id,
        //image: "pin-blue.png",
        //image: "/hsimage.svg",
       
        //image: "pin-blue.png",
        //image: "/hsimage.svg",
        //image: "bluecircle.png",
        
        /*
         circle:20,
         svgStyle: {
           fill       : 'rgba(205, 209, 228, 0.5)',
           //stroke     : 'rgba(11, 156, 50, 0.6)',
           stroke     : 'rgba(144, 198, 149, 0.9)',
           strokeWidth: '4px'
         },
*/
        //image: "bluecircle.png",
        //size: { width: 60, height: 60 },
        position: { yaw: value.location.pan+'deg', pitch: -value.location.tilt+'deg' },
        //tooltip: "Image embedded in the scene",
        //tooltip: "",
         element: markersDivParent,
         //elementLayer: markersDivParent,
         /*
        tooltip: {
          id:"lol",
          position: 'top',
          trigger: 'click',
          isVisible:'true'
      },
*/
      });
    }
    }
    let maxsize = 10000 /(value.distance*8)
    if (maxsize <= 0)
      maxsize = 20

    
    function lol(val, min, max){
      let num = (val - min) / (max - min);
      //let num = (val-min*val)/(max*val-min*val)
      return num

    }
    let test = lol(value.distance,1,500)
    //console.log('number' , test)

    maxsize = (30/(test))*2

    if (value.clicktype =="walkpoint")
    {
      markersPlugs.addMarker({
        id: value.id,
        //ellipse: [maxsize, maxsize],
        //ellipse: [maxsize*2, (value.location.tilt*1)],
        circle:maxsize/4,
        //image: "pin-blue.png",
        //image: "/hsimage.svg",
        //image: "bluecircle.png",
        svgStyle: {
          fill       : 'rgba(11, 156, 50, 0.4)',
          //stroke     : 'rgba(11, 156, 50, 0.6)',
          stroke     : 'rgba(2, 100, 10, 0.6)',
          strokeWidth: '4px'
        },
        //size: { width: value.distance, height: value.distance },
        position: { yaw: value.location.pan+'deg', pitch: -value.location.tilt+'deg' },
       
        
/*
        tooltip: {
          content: 'An HTML marker',
          position: 'right',
      },
*/
      });
    }

      if (value.clicktype =="polygon")
        {
          let polyarray = []
           for (const [key, value2] of Object.entries(value.polygon)) {
            let simple = [value2.pan+'deg', -value2.tilt+'deg']
            polyarray.push(simple)

/*
              markersPlugs.addMarker({
        id: Math.random(),
        //image: "pin-blue.png",
        image: "/hsimage.svg",
        //image: "bluecircle.png",
        size: { width: 20, height: 20 },
        position: { yaw: value2.pan+'deg', pitch: -value2.tilt+'deg' },
        //tooltip: "Image embedded in the scene",
        tooltip: {
          id:"lol",
          position: 'top',
          trigger: 'click',
          isVisible:'true'
      },
      });
*/

            
          }

          markersPlugs.addMarker({
            id: value.id,
            //image: "pin-blue.png",
            //image: "bluecircle.png",
            /*
            polygon: [
              [5.7107, -0.6352], [5.978, -0.60319], [5.9802, -0.3756], [5.7149, -0.4152]
          ],
*/
polygon: polyarray,
          svgStyle: {
              fill: 'rgba(11, 156, 50, 0.3)',
              stroke: 'rgba(11, 156, 50, 0.3)',
              strokeWidth: '2px'
          },

            //tooltip: "Image embedded in the scene",
            tooltip: {
              id:"lol",
              position: 'top',
              trigger: 'click',
              isVisible:'true'
          },
          });
        }
  
      

      //addDynamicHtml(value.id,("psv-marker-"+value.id))
    }
  }
    
  }


  //////////////////////////////////
////////////////////////////////// LIGHTSTREAM (360 spin around function)
//////////////////////////////////


async function GetProjectInfoLightstream()
{
  let projectidarg = id
  let projectdata
  //let target = getLocationParams().target
        if (projectidarg)
        {
          //console.log(projectidarg)
        //projectdata= await GetProjectData(projectidarg)
        //console.log('hello in here')
        //let target = GetLocationParams().target?GetLocationParams().target:""
        if (target!=undefined)
        {
          //console.log('searching')
          const q = query(collection(db, "stream",projectidarg, 'lightstream'), where("id", "==", target));
          const querySnapshot = await getDocs(q);
          let docu;
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            projectdata=doc.data()
          });
          
          //return docu
        }
        }
        //console.log(JSON.parse(projectdata.points.sans.points))
        return projectdata
        
}

  const handleKeyDown = (ev) => {
   
    if (rotationDisabled) return;

    if (ev.key === 'ArrowLeft' || ev.key === 'ArrowRight') {
      setRotationDisabled(true);
    }

  };

  
  const ImageChanged = (ev) => {
      if (buttons.length>0)
        {
          buttons.forEach(button => {
            let btx= document.getElementById("hello").offsetWidth*button.points[ev][0]
            let bty= document.getElementById("hello").offsetHeight*button.points[ev][1]
            document.getElementById(button.name).style.left=(btx.toString()+'px')
            document.getElementById(button.name).style.top=(bty.toString()+'px')
          });
        
        }
      }

  function setNewImageArrayHorizontal () {
    //let target= 'Nordbohusbyen'
var projectFolder = "https://fortrinn.s3.eu-north-1.amazonaws.com/projects/"+id+"/lightstream/"+target+"/";
let imgMaxHorizontalSteps = 48
let tempImgArray= []
    //horizontal array
    for (let index = 1; index <= imgMaxHorizontalSteps; index++) {
      let imgLink = `${projectFolder}${target}_H${index}.jpg`;
      tempImgArray.push(imgLink);
    }
    return tempImgArray;
  }


  const FloatingButtonPr = (name) => {
    let url= window.location.origin +'/?id='+ id + '&target='+ name
    //console.log(url)
    window.open(url,"_blank")
  } 



//////////////////////////////////
////////////////////////////////// LIGHTSTREAM ENd
//////////////////////////////////


  
  const plugins = [
    [
      MarkersPlugin,
      {
        defaultHoverScale: true,
        // list of markers. Empty because it is set from MakeMarkers function
        markers: [
          

        ]
      }
    ]
  ];


       const photoSphereRef = React.useRef();


       const handleClick = (instance) => {
        if (debug)
        console.log('yaw ' + instance.data.yaw, ' pitch: '+ instance.data.pitch)
      }

      const handlePosChange = (x,y,event) => {
        //console.log('pitch '+x + ' yaw ' +y)
        //console.log(event.state.direction)
        //console.log('yaw ' + instance.data.yaw, ' pitch: '+ instance.data.pitch)
      }
      
function CacheSunImagesPano()
{
  if (debug)
  console.log('CacheSunImagesPano() creatomg sun images')

  MakeMarkers()


  imagesPano = []
  try {
    
    if (selected.sun.length>0)
      {
        
        selected.sun.forEach(element => {
          let link = selected.image
          link = link.slice(0, -4) +"_H"+ element + ".jpg"
          imagesPano.push(link)
          //console.log(link)
        });


        if (imagesPano)
        {
          imagesPano.forEach(element => {
            if (imagesPano[imagesPano.length-1] == element)
            {
              fetch(element, {
              }).then((response) => {
                setSunslider(true);
                //console.log('sunslider loaded complete', element)
              })
            }
            else
            {
              fetch(element, {
              })
            }
          });
        }

      }
      else
      {
        setSunslider(false);
      }
  } catch (error) {
    setSunslider(false);
  }
     
/*
  if (markersPlugs)
  {
    if (showSlider)
   markersPlugs.showAllMarkers()
  else
  markersPlugs.hideAllMarkers()
  }
*/
}

const handleReady = (instance) => {
  if (debug)
  console.log('handleReady() Panorama is ready');
  markersPlugs = instance.getPlugin(MarkersPlugin);
  if (!markersPlugs)
    return;



// CACHING IMAGES AND PREPARING FOR SUNSLIDER IN PANORAMA
  //console.log(data)
  //console.log(selected.sun.length)
  CacheSunImagesPano()
  /*
  imagesPano = []
  
  try {
    
    if (selected.sun.length>0)
      {
        
        selected.sun.forEach(element => {
          let link = selected.image
          link = link.slice(0, -4) +"_H"+ element + ".jpg"
          imagesPano.push(link)
          //console.log(link)
        });


        if (imagesPano)
        {
          imagesPano.forEach(element => {
            if (imagesPano[imagesPano.length-1] == element)
            {
              fetch(element, {
              }).then((response) => {
                setSunslider(true);
                //console.log('sunslider loaded complete', element)
              })
            }
            else
            {
              fetch(element, {
              })
            }
          });
        }

      }
  } catch (error) {
    
  }
//END CACHING PANORAMA IMAGES 
*/

//MakeMarkers()

  //console.log(markersPlugs);
  markersPlugs.addEventListener("select-marker", (plug) => {
    setHoverPlateUnit(null)
    //If we want to reload the page with the new href window
/*
    let url= window.location.origin +'/?id='+ id + '&target='+ plug.marker.config.id
    console.log(url)
    window.open(url,"_self")
    return 
*/

let tempid = plug.marker.config.id
//console.log('pressing ', plug.marker.config)
//console.log('selected was  ', selected)


//ITS GETTING THE WRONG SELECTED INFO SINCE THE STATE OF SELECTED IS NOT UPDATED HERE 
/*
let portal = false
if (selected.markers[plug.marker.config.id])
{
console.log('isPortal? ', selected.markers[plug.marker.config.id].portal)
if (selected.markers[plug.marker.config.id].portal)
  portal = true
}



console.log(tempselected)
if (portal)
{
  //console.log('portalcheck ' , selected.markers[plug.marker.config.id].startrotation.yaw)
  if (tempselected.startrotation)
  {

  }
  else
  {
    //tempselected.startrotation = {}
  }


    console.log('has startrot')
    let temp = {yaw:selected.markers[plug.marker.config.id].startrotation.yaw, pitch: selected.markers[plug.marker.config.id].startrotation.pitch}
    tempselected.startrotation = temp



   //tempselected.startrotation.yaw = selected.markers[plug.marker.config.id].startrotation.yaw
  //tempselected.startrotation.pitch = selected.markers[plug.marker.config.id].startrotation.pitch
  
  //tempselected.override.startrotation.yaw = selected.markers[plug.marker.config.id].startrotation.yaw
  //tempselected.override.startrotation.pitch = selected.markers[plug.marker.config.id].startrotation.pitch
  //newData[tempid]
}
*/

  //setSelected(newData[tempid])
  
/*
  let newData = data
let tempselected = JSON.parse(JSON.stringify(newData[tempid]))
setSelected(tempselected)
*/

  //selected2 = data[tempid]
  change_url(tempid)
  });

 



/*
window.onhashchange = function() {
  console.log('hash change')
  if (window.innerDocClick) {
      //Your own in-page mechanism triggered the hash change
  } else {
      //Browser back button was clicked
  }
*/

markersPlugs.addEventListener("leave-marker", (plug) => {
//console.log('leave marker')
setHoverPlateUnit(null)
})

  markersPlugs.addEventListener("enter-marker", (plug) => {
    //console.log(data[plug.marker.config.id])
    //console.log(selected.markers[plug.marker.config.id])



    //Show hoverplate when mouseover on a marker that is a polygon
    //PS: Maybe the marker can have an attribute so that we can show hoverplate on other things if we want that?
    if (selected.markers[plug.marker.config.id])
    {}
      else
    return

    
    if (selected.markers[plug.marker.config.id].polygon)
    {
      //console.log(panoTags)
      if (panoTags[plug.marker.config.id])
        setHoverPlateUnit(panoTags[plug.marker.config.id])
      else
      {
        let plate = {...data[plug.marker.config.id]}
        //plate.image="https://fortrinn.s3.eu-north-1.amazonaws.com/projects/3iUfCJSzDaWjUS5XFBhY/pano/lun_stue2etg_thumbnail.jpg"
        plate.image = plate.thumb
        //setHoverPlateUnit(data[plug.marker.config.id])
        setHoverPlateUnit(plate)
      }

    }


    //setTestLol(data[plug.marker.config.id])
    /*
    var element = document.createElement("div");
    
    element.innerHTML=(
      `<div class="container">
      <button style="position:'relative';border: none;background: none;padding: 0;">
        <h1 style="font-size:10px;z-index:4000;color:white;text-shadow: 1px 1px black">blabla</h1>
        </button>
      </div>`
    );
  
    var foo = document.getElementById("psv-marker-"+plug.marker.config.id);
    element.style = ("top:-40px;position:fixed;z-Index:3000")
    foo.appendChild(element);
*/
/*
var foo = document.getElementById("psv-marker-"+plug.marker.config.id);
let markersDivParent = document.createElement("div");
                const root = createRoot(markersDivParent);
                root.render(lol2(foo));
*/

    //console.log("hello");

    if (debug)
    console.log(plug.marker.config.id)
    if (debug)
    console.log(data[plug.marker.config.id].title)

    //markersPlugs.hideAllMarkers()
  });

}


function change_url(configId) { 
  //let url= window.location.origin +'/?id='+ id + '&target='+ configId
  //window.location.assign(url);
  //pushState
  /*
  window.history.replaceState("stateObj", 
      "new page", url); 
*/
let newData = data
let tempselected = JSON.parse(JSON.stringify(newData[configId]))
setSelected(tempselected)

let url= '/?id='+ id + '&target='+ configId;

if (debug)
console.log('Button clicked, changing target url to ', url)


navigate(url);
/*
navigate('/', {
  id: id,
  target: configId
});
*/


/*
      window.history.pushState("stateObj", 
        "new page", url); 
*/

} 


function handleGoToProjectButton (link) {
  //window.parent.postMessage(message, "*");
  //let temp = {replace:{homepage:link}}
    //window.postMessage(temp, "*");
  //WebBackBtPr();
  window.location.assign(link);
}




function Greeting({ name }) {
  return createElement(
    'h1',
    { className: 'greeting' },
    'Hello'
  );
}





const lol2 = (id) => {
  return <div>
        <p className={'marker-name'}>HelloWat</p>
        <div className={'marker'}></div>
    </div>
}
function addDynamicHtml(innerText,id) {
  //Creates a button and appends it to an object. Remember that markers has a string in front of its id
  //Create an input type dynamically.  

  var foo = document.getElementById(id)
  //const body = React.createElement('p', {}, 'Lorem ipsum dolor sit amet');
  //const X = React.createElement('div', {}, [foo, body]);


  const node = document.createElement("li");
  node.style=("top:-40px;position:absolute;")
const textnode = document.createTextNode("Water");
node.appendChild(textnode);
foo.appendChild(node);
 console.log(foo)
  //console.log('CREATING')
  //var element = document.createElement("div");
  //element.position="absolute"

  // element.innerHTML=(
  //   `<div class="container">
  //   <button style="border: none;background: none;padding: 0;">
  //     <h1 style="font-size:30px;color:white;text-shadow: 1px 1px black">${innerText}</h1>
  //     </button>
  //   </div>`
  // );

  //var foo = document.getElementById(id);
  //element.style = ("top:-40px;position:absolute;")
  //foo.appendChild(element);


/*
  var foo = document.getElementById("psv-marker-"+id);
let markersDivParent = document.createElement("div");
                const root = createRoot(markersDivParent);
                root.render(WAT(foo));

*/



}

const handleChange = (event, newValue) => {
  if (newValue != number)
  {
  setSliderValue(newValue);
  number = newValue
  //overlayPlugs.clearOverlays()

  /*
  overlayPlugs.addOverlay({
    path: images[number],
    opacity:1,

})
*/
  photoSphereRef.current.setPanorama(imagesPano[number],{transition:false,showLoader:false})
  }
};
const handleDownSun = (event) => {
  if (markersPlugs)
    {
    markersPlugs.hideAllMarkers()
    }

}
const handleUpSun = (event) => {
  if (markersPlugs)
    {
     markersPlugs.showAllMarkers()
    }
}

const slidermarks = [
  {
    value: 0,
    label: '08:00',
  },
  {
    value: 1,
    label: '10:00',
  },
  {
    value: 2,
    label: '12:00',
  },
  {
    value: 3,
    label: '14:00',
  },
  {
    value: 4,
    label: '16:00',
  },
  {
    value: 5,
    label: '18:00',
  },
  {
    value: 6,
    label: '20:00',
  }

];

function valuetext(value) {
  return `${slidermarks[value].label}`;
}

function CustomThumb(props) {
  const { children, ...other } = props;
  return (
      <SliderThumb {...other}>
        {children}
        <WbSunny  width='100%' height='100%'/>
      </SliderThumb>
  );
}

function ShowSunSlider(value) {
  /*
  if (!showSlider)
    markersPlugs.hideAllMarkers()
else
markersPlugs.showAllMarkers()
*/
 setShowSlider(!showSlider)
}

function WAT(){
  return(<div>HEfasfasfasfasfsaLLO</div>)
}

const Boligsnarveier = () =>{
  return(
<div><Fade in={mainBuildingUI} >
    <div style={{position:"absolute",width:'100%',bottom:'0px',zIndex:'1000'}}>
    
      <Box display="flex" width={'100%'}  style={{overflowX:'auto'}}>
        <Box m="auto">
          <Stack direction={'row'} alignSelf={'center'} alignContent={'center'} spacing={2} p={1} style={{}}>
              {mainhouseButtons.map(product => {
                    return <ImageCards key={product.id+"imagecard"} title={product.title} clickevent={ImagecardPr} id={product.id} image={product.image} infotext={product.infotext}></ImageCards>
              })}
          </Stack>
        </Box>
        
      </Box>
{/*             
      <Box display="flex" width={'100%'}  style={{overflowX:'auto',position:'absolute',zIndex:'10000'}}>
        <Button width={'100%'} style={{ position:'fixed',bottom:'250px', minWidth:'100%', backgroundColor:colorTheme.background, height:'30px', border:'solid rgba(200, 200, 200, 0.3) 1px',zIndex:'10001'}} onClick={() => setMainBuildingUI(!mainBuildingUI)}>
        <KeyboardDoubleArrowDownIcon style={{color:colorTheme.icon}} ></KeyboardDoubleArrowDownIcon>

          </Button>
          </Box>
           */}
    </div>
    </Fade>
</div>)



  if (mainBuildingUI)
  return(<div><Fade in={mainBuildingUI} >
    <div style={{position:"absolute",width:'100%',bottom:'0px',zIndex:'1000'}}>
    
      <Box display="flex" width={'100%'}  style={{overflowX:'auto'}}>
        <Box m="auto">
          <Stack direction={'row'} alignSelf={'center'} alignContent={'center'} spacing={2} p={1} style={{}}>
              {mainhouseButtons.map(product => {
                    return <ImageCards key={product.id+"imagecard"} title={product.title} clickevent={ImagecardPr} id={product.id} image={product.image} infotext={product.infotext}></ImageCards>
              })}
          </Stack>
        </Box>
        
      </Box>
{/*             
      <Box display="flex" width={'100%'}  style={{overflowX:'auto',position:'absolute',zIndex:'10000'}}>
        <Button width={'100%'} style={{ position:'fixed',bottom:'250px', minWidth:'100%', backgroundColor:colorTheme.background, height:'30px', border:'solid rgba(200, 200, 200, 0.3) 1px',zIndex:'10001'}} onClick={() => setMainBuildingUI(!mainBuildingUI)}>
        <KeyboardDoubleArrowDownIcon style={{color:colorTheme.icon}} ></KeyboardDoubleArrowDownIcon>

          </Button>
          </Box>
           */}
    </div>
    </Fade>
</div>)


}

const OversiktPr = (targ) => {
  //setSelectedUnit('nordbohusbyen');
  change_url(targ)
  //setMainBuildingUI(true)
}

function BoligvelgerVisible(){
  if (boligvelger)
  return(<div>  <Box sx={unitPreviewCardsStyle}>
    <Stack direction={'column'} spacing={2} padding={1}>
        {
            showSelected &&
            <Fade in={showSelected} timeout={{enter: 500, exit: 500}} easing={"ease-out"}>
                <div style={{minHeight: '0px'}}>
                    <UnitCard unit={selectedUnit} handleCloseCallback={() => setSelectedUnit(null)} />
                </div>
            </Fade>
        }
        {
            showHover &&
            <Fade in={showHover} timeout={{enter: 500, exit: 500}} easing={"ease-out"}>
                <div>
                    <UnitCard unit={hoverUnit} comparisonVersion/>
                </div>
            </Fade>
        }
    </Stack>
</Box>
<UnitList onSelectedCallback={handleOnSelectedUnit} onHoverCallback={unit => setHoverUnit(unit)} units={units} /></div>)
else
return (<></>)
}


const HoverInfoPlate = () => {

 

  if (hoverPlateUnit)
  {
    let hoverStyle = {
      position: 'absolute',
      left: '0px',
      top: '0px',
      width: '350px',
      zIndex: 100,
    }
    
    //console.log(platePos)
  return(<div>  
    {/* <Box right={platePos.x} style={{position:'absolute',width:'350px',zIndex:'100'}}> */}
    <Box id={"hoverPlate"} sx={hoverStyle}>
    <Stack direction={'column'} spacing={2} padding={1}>
            <Fade in={true} timeout={{enter: 500, exit: 500}} easing={"ease-out"}>
                <div>
                    {/* <UnitCard unit={hoverPlateUnit} comparisonVersion/> */}
                    {/* <ImageCards key={hoverPlateUnit.id+"imagecard"} title={hoverPlateUnit.title} clickevent={ImagecardPr} id={hoverPlateUnit.id} image={hoverPlateUnit.image} infotext={hoverPlateUnit.infotext}></ImageCards> */}

                    <Card onClick={() => console.log('')}  sx={{ maxWidth: 200, minWidth: 200,maxHeight:300,minHeight:110,backgroundColor: 'rgb(240, 240, 240, 1)', ':hover':{backgroundColor: 'rgb(240, 240, 240, 0.42)'}}}>
                     
{/*                      
                      <CardMedia
                        component="img"
                        alt=""
                        height="140"
                        image={hoverPlateUnit.thumb}
                        //src={`${hoverPlateUnit.image}?cachebuster=${new Date().getTime()}`}
                        //style={{minWidth:"200"}}
                      />

                      <CardContent style={{padding: '0px'}}>
                        <Typography gutterBottom variant="h5" component="div">
                          {hoverPlateUnit.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        </Typography>
                      </CardContent>
 */}

      <CardMedia
              component="img"
              alt=""
              height={smallscreen==true?100:180}
              //src={`${props.image}?cachebuster=${new Date().getTime()}`}
              image={hoverPlateUnit.image}
              //style={{minWidth:"200"}}
            />
              
            <CardContent style={{padding: '0px'}}>
              <Typography gutterBottom variant="h5" component="div" sx={{fontSize: smallscreen==true?'0.85rem':"1.3rem" }}>
              {hoverPlateUnit.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {/* {props.infotext}  */}
              </Typography>
            </CardContent>
            {/* <Button size="small" onClick={() => window.open(currentHouseLink.link,"_self")}>BESØK Prosjektside</Button> */}
            {hoverPlateUnit.bra?
            <CardActions>
              {/* <Button size="small" onClick={() => window.open(currentHouseLink.link,"_self")}>BESØK Prosjektside</Button> */}
              
              <Typography gutterBottom variant="body1" sx={{fontSize: smallscreen==true?'0.85rem':"1rem" }}>BRA {hoverPlateUnit.bra} m²</Typography>
              <Divider orientation="vertical"  flexItem ></Divider>
              <Typography gutterBottom variant="body1" sx={{fontSize: smallscreen==true?'0.85rem':"1rem" }}>Soverom {hoverPlateUnit.soverom}</Typography>
              
              
            </CardActions>
            :<></>}

                    </Card>


                </div>
            </Fade>
    </Stack>
</Box>
</div>
)
  }
else return (<></>)
}


if (data == null || selected==null)
  return (<>
  </>)

  if (viewstyle =="turntable")
    return (
      <div id={'gsdgsd'} style={{position:'relative',top:'40px',  borderBlockColor:'black',width:'100%',borderStyle:'solid',left:'calc(0%)',   marginLeft: 'auto',maxWidth:'1200px', marginRight: 'auto', overflow: "clip", flex: 1}}>
    
    <div><ReactImageTurntable id='hello'
      style={{height:'100%'}}
            images={images}
            autoRotate={{ disabled:true}}
            onPointerDown={() => setRotationDisabled(true)}
            onPointerUp={() => setRotationDisabled(false)}
            onKeyDown={handleKeyDown}
            onKeyUp={() => setRotationDisabled(false)}
            onIndexChange={ImageChanged}
            >
               
            </ReactImageTurntable>
             {
              buttons.map((button) => 
                <Button
                id={button.name}
                style={{position:'absolute', height: '40px', width: '40px',zIndex:'1000000'}}
                sx={ { borderRadius: 28,color:'black',backgroundColor:'white', borderColor:'grey',borderWidth:'3px',fontSize:20,zIndex:'10000'} }
                variant={'outlined'}
                onClick={() => {FloatingButtonPr(button.name)}}
            >
              {button.name}
              </Button>
              )
              }
          </div>
          </div>)




return (
    <div >
      {/* style={{filter:'brightness(1.2) saturate(110%)'}} */}
      {/* <WAT></WAT> */}
{/* FANTASTISK MÅTE Å SENTRERE PÅ */}
{/* 
      <div>
      <Grid container sx={{position:'absolute', justifyContent: 'center', zIndex:'2000' }}>
        <Grid item>
    <Tooltip followCursor={true}><Typography>HELLO</Typography></Tooltip>
    </Grid>
    </Grid>
    </div> */}
{/* bgcolor={colorTheme.background} */}
<HoverInfoPlate/> 
{/* <DialogSelector/> */}


<div style={{position:"absolute",zIndex:'10000', width:'100%',pointerEvents:'none'}} align={'center'}>
  {smallscreen?
      <div align={'center'} style={{position:"fixed",right:'1px', top:'8px', marginLeft:'170px', color: 'white',textShadow: '1px 1px #777',fontWeight: 600}}>
          <Typography  sx={{fontSize: '0.7rem'}}>{"Visningsmodellen er kun veiledende, og avvik vil forekomme"}</Typography>

    </div>
    :
    <div align={'center'} style={{top:'40px',color: 'white',textShadow: '1px 1px #777',fontWeight: 600}}>
          <Typography  sx={{fontSize: '1.2rem' }}>{"Visningsmodellen er kun veiledende, og avvik vil forekomme. Merk forbeholdstekst"}</Typography>

    </div>
  }
    </div>




 {/* TOP BAR */}
 {/* topbar */}
 {topbar ?
 
        <div style={{position:"absolute",width:'100%',top:'0px',zIndex:'500'}}>
          <div style={{position:'fixed',left:'1px'}}>
       
        <div style={{position:'fixed',width:'100%',top:'0px', left:0}}>
          <Box display="flex" width={'100%'} bgcolor={topbar.color} style={{overflowX:'auto', height:'50px'}}>
            <Box m="auto">
              <Stack direction={'row'} alignSelf={'center'} alignContent={'center'} spacing={2} p={1} style={{}}>
                
              </Stack>
            </Box>
          </Box>
          </div>
          <Button onClick={() => handleGoToProjectButton(topbar.link)} style={{position:"fixed"}}><img src={topbar.icon} height={'40px'}></img><Typography style={{paddingLeft:'4px',color:'white'}}>{topbar.text}</Typography></Button>
          </div>
          <Box display="flex" width={'100%'} bgcolor={colorTheme.background} style={{overflowX:'auto',position:'absolute',zIndex:'0'}}>
              </Box>
        </div>
        :<></>}



{/* <Boligsnarveier/> */}

{/* 
{hoverPlateUnit?
<div>
<Box sx={unitPreviewCardsStyle}>
    <Stack direction={'column'} spacing={2} padding={1}>
            <Fade in={true} timeout={{enter: 500, exit: 500}} easing={"ease-out"}>
                <div>
                   
                    <Card onClick={() => console.log('click')}  sx={{ maxWidth: 200, minWidth: 200,maxHeight:300,minHeight:110,backgroundColor: 'rgb(240, 240, 240, 1)', ':hover':{backgroundColor: 'rgb(240, 240, 240, 0.42)'}}}>
                      <CardMedia
                        component="img"
                        alt=""
                        height="140"
                        image={hoverPlateUnit.image}
                        //style={{minWidth:"200"}}
                      />
                      <CardContent style={{padding: '0px'}}>
                        <Typography gutterBottom variant="h5" component="div">
                          {hoverPlateUnit.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        </Typography>
                      </CardContent>
                    </Card>


                </div>
            </Fade>
    </Stack>
</Box>
</div>
:<></>} */}


{/* Knapp som åpner og lukker Oversikt av de forskjellige byggene */}
<div>
  <Fade in={true} >
    <div style={{position:"absolute",zIndex:'10000'}}>
      <div style={{position:"fixed",left:'1px', top:'60px'}}>
      {/* <Button variant={'outlined'} style={{backgroundColor:colorTheme.background}} onClick={() => OversiktPr()}> GÅ til oversikt av hus</Button> */}
          <Button variant={'outlined'} style={{borderRadius:0, color:'white',maxHeight: smallscreen==true?'20px':"50px", backgroundColor:"rgba(62, 85, 102, 1)"}} onClick={() => OversiktPr(mainTarget)}> 
            
  <Typography sx={{fontSize: smallscreen==true?'0.7rem':"1.2rem" }}>OVERSIKT</Typography>
          </Button>
          {selected.panotags? <Button variant={'text'} style={{borderRadius:0, color:'white'}} onClick={() => OversiktPr(selected.panotags)}> 
            
            
            <Typography sx={{fontSize: smallscreen==true?'0.7rem':"1.2rem" }}>{'>'} {selected.panotags}</Typography>

          </Button> : <></>}
          
          
    </div>

{/* 
    <div style={{position:"fixed",left:'20px', top:'150px'}}>
          <Button variant={'contained'} style={{backgroundColor:colorTheme.background}} onClick={() => setMainBuildingUI(!mainBuildingUI)}> Se info om valgt Hus</Button>
    </div> */}


    </div>

  </Fade>
</div>


{/*Oversikt av de forskjellige byggene */}
<div>
  {/* <Fade in={false} > */}
  <Fade in={mainBuildingUI} >
    <div style={{position:"absolute",width:'100%',bottom:'0px',zIndex:'2000',pointerEvents:'none'}}>
    
      <Box display="flex" width={'100%'}  style={{overflowX:'auto',pointerEvents:'auto'}}>
        <Box m="auto">
          <Stack direction={'row'} alignSelf={'center'} alignContent={'center'} spacing={2} p={1} style={{ pointerEvents:'auto'}}>
              {mainhouseButtons.map(product => {
                    return <ImageCards key={product.id+"imagecard"} smallscreen={smallscreen} title={product.title} clickevent={ImagecardPr} id={product.id} image={product.image} infotext={product.infotext}></ImageCards>
              })}
          </Stack>
        </Box>
        
      </Box>
{/*             
      <Box display="flex" width={'100%'}  style={{overflowX:'auto',position:'absolute',zIndex:'10000'}}>
        <Button width={'100%'} style={{ position:'fixed',bottom:'250px', minWidth:'100%', backgroundColor:colorTheme.background, height:'30px', border:'solid rgba(200, 200, 200, 0.3) 1px',zIndex:'10001'}} onClick={() => setMainBuildingUI(!mainBuildingUI)}>
        <KeyboardDoubleArrowDownIcon style={{color:colorTheme.icon}} ></KeyboardDoubleArrowDownIcon>

          </Button>
          </Box>
           */}
    </div>
    </Fade>
</div>

{/* buttonHouseNavigation */}
{/* STORT BILDE AV VALGT HUS  */}
{buttonHouseNavigation?
<div>
  <Fade in={buttonHouseNavigation} >
    <div style={{position:"absolute",zIndex:'10000'}}>
      <div style={{position:"fixed",left:'1px', bottom:'0px'}}>
      {/* <Button variant={'outlined'} style={{backgroundColor:colorTheme.background}} onClick={() => OversiktPr()}> GÅ til oversikt av hus</Button> */}
      <Box m="auto">
          <Stack direction={'row'} alignSelf={'center'} alignContent={'center'} spacing={2} p={1} style={{ pointerEvents:'auto'}}>

<Card onClick={() => window.open(currentHouseLink.link,"_blank")}  sx={{borderRadius: 0,borderRadius:'5px',border:'3px', borderBlockStyle:'outset', borderColor:'white', maxWidth: smallscreen==true?160:220, minWidth: smallscreen==true?100:200, maxHeight:smallscreen==true?180:400,backgroundColor: 'rgb(240, 240, 240, 1)', ':hover':{backgroundColor: 'rgb(240, 240, 240, 0.42)'}}}>
     
      <CardMedia
        component="img"
        alt=""
        height={smallscreen==true?100:180}
        //src={`${props.image}?cachebuster=${new Date().getTime()}`}
        image={currentHouseLink.image}
        //style={{minWidth:"200"}}
      />
      <CardContent style={{padding: '0px'}}>
      <Button size="small" style={{padding: '5px 0px'}} onClick={() => window.open(currentHouseLink.link,"_blank")}>
        <Typography gutterBottom variant="h5" component="div" sx={{textTransform:"none", fontSize: smallscreen==true?'0.8rem':"1.1rem" , fontFamily: 'sans-serif'}}>
        Prosjektside {currentHouseLink.title}
        </Typography>
        </Button>
        <Typography variant="body2" color="text.secondary">
          {/* {props.infotext}  */}
        </Typography>
      </CardContent>
      {/* <Button size="small" onClick={() => window.open(currentHouseLink.link,"_self")}>BESØK Prosjektside</Button> */}
      {currentHouseLink.bra?
      <CardActions>
        {/* <Button size="small" onClick={() => window.open(currentHouseLink.link,"_self")}>BESØK Prosjektside</Button> */}
        
        <Typography gutterBottom variant="body1" sx={{fontSize: smallscreen==true?'0.85rem':"1rem" }}>BRA {currentHouseLink.bra} m²</Typography>
        <Divider orientation="vertical"  flexItem ></Divider>
        <Typography gutterBottom variant="body1" sx={{fontSize: smallscreen==true?'0.85rem':"1rem" }}>Soverom {currentHouseLink.soverom}</Typography>
        
        
      </CardActions>
      :<></>
    }
    </Card>

{/* 
          <Button variant={'outlined'} style={{borderRadius:0, color:'white',maxHeight: smallscreen==true?'20px':"50px", backgroundColor:"rgba(62, 85, 102, 1)"}} onClick={() => window.open(currentHouseLink.link,"_self")}> 
            <Typography sx={{fontSize: smallscreen==true?'0.7rem':"1.2rem" }}>MELD INTERESSE AV {currentHouseLink.title}</Typography>
          </Button> */}


          </Stack>

          
          </Box>
    </div>
    </div>
  </Fade>
</div>
:<></>
}


{/* Oversikt over de viktigste punktene i et hus, for å lettere navigere seg rundt interiør */}
<div>
{/* buttonHouseNavigation */}
{/* left:smallscreen==true?'150px':'0px' */}
<Fade in={buttonHouseNavigation} >
    <div style={{position:"absolute",width:'100%',bottom:'0px',zIndex:'1000',pointerEvents:'none'}}>
      <Box display="flex" width={'100%'}  style={{overflowX:'auto',pointerEvents:'auto'}}>
        <Box m="auto" >
          <Stack direction={'row'} alignSelf={'center'} alignContent={'center'}  spacing={2} p={1} style={{pointerEvents:'auto'}}>
              {buttonHouse.map(product => {
                    return <HouseNavigationCards key={product.id+"imagecard"} smallscreen={smallscreen} title={product.title} clickevent={ImagecardPr} id={product.id} image={product.thumb} infotext={product.infotext}></HouseNavigationCards>
              })}
          </Stack>
        </Box>
        
      </Box>
{/*             
      <Box display="flex" width={'100%'}  style={{overflowX:'auto',position:'absolute',zIndex:'10000'}}>
        <Button width={'100%'} style={{ position:'fixed',bottom:'250px', minWidth:'100%', backgroundColor:colorTheme.background, height:'30px', border:'solid rgba(200, 200, 200, 0.3) 1px',zIndex:'10001'}} onClick={() => setMainBuildingUI(!mainBuildingUI)}>
        <KeyboardDoubleArrowDownIcon style={{color:colorTheme.icon}} ></KeyboardDoubleArrowDownIcon>

          </Button>
          </Box>
           */}
    </div>
    </Fade>
</div>


      {sunslider?
      <div style={{position:'absolute', bottom:'100px', right:'16px'}}>
      <Fab style={{backgroundColor:"rgba(192, 192, 192,0.5)"}} aria-label="add" onClick={ShowSunSlider} >
        <WbSunny style={{color:'yellow'}}/>
      </Fab>
      </div>
      :<></>}
      <div>

    {sunslider && showSlider?  <div style={{position: 'absolute', 
        left: '50%', 
        bottom:'20px',
        transform: 'translate(-50%, -0%)',
        zIndex:'1000'}}>
      <Box style={{width:'80vw', maxWidth:'800px',paddingLeft:'20px',paddingRight:'20px'}}> 
        <div >
      <Slider
  aria-label="Always visible"
  defaultValue={0}
  //value={value}
  min={0}
  max={imagesPano.length-1}
  step={1}
  marks={slidermarks}
  valueLabelDisplay="auto"
  getAriaValueText={valuetext}
  valueLabelFormat={valuetext}
  //onPositionChange={handleChange}
  onPointerDown={handleDownSun}
  onPointerUp={handleUpSun}
  onChange={handleChange}
  /*
  slots={{
    thumb: CustomThumb
  }}
*/

  sx={{
    color: 'white',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    backgroundImage: 'url(pin-blue.png)',
    height: 30,
    width: 30,
    padding: '5px',
    //backgroundColor: 'rgba(192, 192, 192,1)',
    color:'yellow',
    filter: 'drop-shadow(0px 2px 2px rgba(17, 17, 17, 0.2))',
    border: '1px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
  },
    '& .MuiSlider-markLabel': {
      display:'block',
      color: 'white',
      backgroundColor:"rgba(192, 192, 192,0.1)",
      textShadow:'1px 1px 2px black',
      borderRadius:'5px'
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#52af77',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&::before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    }
  }}
/>
      </div>
      </Box>
      </div> : <></> }
      </div>
      {/* <ReactPhotoSphereViewer src="aurora_00013.png"  ref={photoSphereRef} height={'100vh'} defaultYaw={5.25} defaultPitch={-0.39} width={"100%"} caption={"lol"} hideNavbarButton={true} keyboard={'always'} moveInertia={false} onClick={handleClick} onPositionChange={handlePosChange} onReady={handleReady} plugins={plugins}></ReactPhotoSphereViewer> */}
      <ReactPhotoSphereViewer src={startimage} ref={photoSphereRef}  height={'100vh'} moveSpeed={1.8} defaultZoomLvl={5} defaultYaw={startyaw} defaultPitch={startpitch} width={"100%"} caption={"test"} navbar={false} hideNavbarButton={true} keyboard={'always'} moveInertia={false} onClick={handleClick} onPositionChange={handlePosChange} onReady={handleReady} plugins={plugins}></ReactPhotoSphereViewer>
      

      <BoligvelgerVisible/>
    




    </div>
  );

}

export default Pano;